import React from 'react';
import "./Footer.scss";
import { Link } from 'react-router-dom';
import logo from "../../../assets/Home_Images/Footer-logo.png";
import insta from "../../../assets/Home_Images/insta.png";
import fb from "../../../assets/Home_Images/fb.png";
import twitter from "../../../assets/Home_Images/twtr.png";
import tiktok from "../../../assets/Home_Images/tiktok.png";
import phone from "../../../assets/Home_Images/phone.png";
import location from "../../../assets/Home_Images/location.png";


function Footer() {
  return (
    <section className='footer_container'>
        <div className="footer_nav">
            <div className="logo">
                <Link to= "/"><img src={logo} alt="logo" /></Link>
            </div>

            <div className="links">
                <Link to="/about" className='link'>About</Link>
                <Link to="/service" className='link'>Services</Link>
                <Link to="/contact" className='link'>Contact</Link>
            </div>
        </div> 

        <div className="social-links_wrapper">
            <p>Let's make them Smile!</p>

            <div className="social-links">
                <Link to= "" className='icon'><img src={insta} alt="insta" /></Link>
                <Link to= "" className='icon'><img src={fb} alt="fb" /></Link>
                <Link to= "" className='icon'><img src={twitter} alt="twitter" /></Link>
                <Link to= "" className='icon'><img src={tiktok} alt="tiktok" /></Link>
            </div>

            <div className="contact">
                <div className="phone">
                    <img src={phone} alt="phone" />
                    <span>(612) 986-3354</span>
                </div>

                <div className="location">
                    <img src={location} alt="location" />
                    <span>3601 Portland Ave Minneapolis, MN 55407</span>
                </div>

                <div className="location">
                    <img src={location} alt="location" />
                    <span>621 Trott Ave SW Willmar MN 56201</span>
                </div>
            </div>
        </div>

        <div className="line"></div>

        <div className="copyright">
            <span>© 2023 Center Care Inc</span>
        </div>
    </section>
  )
}

export default Footer