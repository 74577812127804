import React from 'react';
import "./Services.scss";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
//import Carousel from "react-elastic-carousel";
import Card1 from "../../../assets/Home_Images/card1.png";
import Card2 from "../../../assets/Home_Images/card2.png";

// const breakPoints = [
//     {width : 1, itemsToShow: 1},
//     {width : 768, itemsToShow: 2},
//     {width : 1200, itemsToShow: 2},
//     {width : 1600, itemsToShow: 3}
// ]

const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
        {
            breakpoint: 1636,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: false,
                dots: false
              }
        },

        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: false,
                dots: false
              }
        },

        {
            breakpoint: 560,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 2,
                infinite: false,
                dots: true
              }
        },

        {
            breakpoint: 360,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 2,
                infinite: false,
                dots: true
              }
        },
    ]
  };


function Services() {
  return (
    <section className='services_container'>
        <h1><span>Our</span> Services</h1> 

        {/* <Carousel breakPoints={breakPoints}>

            <div className="card_wrapper">
                <img src={Card1} alt="card1-img" />

                <div className="content">
                    <h3>COMPANIONSHIP</h3>
                    <p>Nurturing the souls </p>
                </div>
            </div>

            <div className="card_wrapper">
                <img src={Card2} alt="card1-img" />

                <div className="content">
                    <h3>PERSONAL CARE SERVICES</h3>
                    <p>Caring with all Empathy</p>
                </div>
            </div>

            <div className="card_wrapper">
                <img src={Card1} alt="card1-img" />

                <div className="content">
                    <h3>NURSING CARE</h3>
                    <p>Feel the essence of Nursing</p>
                </div>
            </div>

            <div className="card_wrapper">
                <img src={Card2} alt="card1-img" />

                <div className="content">
                    <h3>MEDICAL ASSISTANCE</h3>
                    <p>Here for you now & always</p>
                </div>
            </div>

        </Carousel> */}

        <Slider {...settings}>

            <div className="card_wrapper">
                <img src={Card1} alt="card1-img" />

                <div className="content">
                    <h3>COMPANIONSHIP</h3>
                    <p>Nurturing the souls </p>
                </div>
            </div>

            <div className="card_wrapper">
                <img src={Card2} alt="card1-img" />

                <div className="content">
                    <h3>PERSONAL CARE SERVICES</h3>
                    <p>Caring with all Empathy</p>
                </div>
            </div>

            <div className="card_wrapper">
                <img src={Card1} alt="card1-img" />

                <div className="content">
                    <h3>NURSING CARE</h3>
                    <p>Feel the essence of Nursing</p>
                </div>
            </div>

            <div className="card_wrapper">
                <img src={Card2} alt="card1-img" />

                <div className="content">
                    <h3>MEDICAL ASSISTANCE</h3>
                    <p>Here for you now & always</p>
                </div>
            </div>

        </Slider>
        
    </section>
  )
}

export default Services;