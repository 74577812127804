import React from 'react';
import Hero from '../components/Contact_Components/Hero/Hero';
import Contact from '../components/Contact_Components/ContactUs/Contact';
import Footer from '../components/Contact_Components/Footer/Footer';
//import Faq from '../components/Contact_Components/FAQ/Faq';
import { Tabtitle } from '../utils/GeneralFunction';

function ContactUs() {
  Tabtitle('Center Care - contact')

  return (
    <>
      <Hero />
      <Contact />
      {/* <Faq /> */}
      <Footer />
    </>
  )
}

export default ContactUs;