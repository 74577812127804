import React from 'react';
import Hero from '../components/Services_Components/Hero/Hero';
import Contact from '../components/Services_Components/ContactUs/Contact';
import Footer from '../components/Services_Components/Footer/Footer';
import Offer from '../components/Services_Components/Service_Offer/Offer';
import OurServices from '../components/Services_Components/Services/OurService';
import RightImgService from '../components/Services_Components/Services/RightImgService'
import { Tabtitle } from '../utils/GeneralFunction';
import ThirdService from '../components/Services_Components/Services/ThirdService';
import FourthService from '../components/Services_Components/Services/FourthService';


function Services() {
  Tabtitle('Center Care - services')

  return (
    <>
      <Hero />
      <Offer />
      <OurServices />
      <RightImgService />
      <ThirdService />
      <FourthService />
      <Contact />
      <Footer />
    </>
  )
}

export default Services;