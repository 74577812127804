import "./Hero.scss";

import React from 'react';
//import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar";


function Hero() {
  return (
    <section className="hero_section">
        <Navbar />
        
        <div className="text_container">
            <h4>We are committed to providing excellent customer service.</h4>
            <h1><span>Get the help you need</span> without delay.</h1>
            <p>With personalized assistance.</p>
            {/* <Link to='/contact' className="hero-btn">Get help</Link> */}
        </div>
    </section>
  )
}

export default Hero;


   // -webkit-text-stroke: 2px #fff;
