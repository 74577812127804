import React from 'react'
import Contact from '../components/Home_Components/ContactUs/Contact';
import CoreValues from '../components/Home_Components/CoreValues/CoreValues';
import Footer from '../components/Home_Components/Footer/Footer';
import Hero from '../components/Home_Components/Hero/Hero';
import Review from '../components/Home_Components/Review/Review';
import Services from '../components/Home_Components/Services/Services';
import WhyCC from '../components/Home_Components/WhyCC/WhyCC';
import { Tabtitle } from '../utils/GeneralFunction';

function Home() {
  Tabtitle('Center Care - home')

  return (
    <>
      <Hero />
      <CoreValues />
      <Services />
      <WhyCC />
      <Review />
      <Contact />
      <Footer />
    </>
  )
}

export default Home;