import React, { useState } from 'react';
import "./Record.scss";

import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';


function Record() {
    const [counterOn, setCounterOn] = useState (false)
  return (
    <section className='countup_container'>
        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
            {counterOn && <div className='section'>
                    <h1><CountUp start={0} end={5} duration={1} delay={0} /> +</h1>
                    <span className='title'> years of experience</span>
                </div>} 
        </ScrollTrigger>

        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
            {counterOn && <div className='section'>
                    <h1><CountUp start={0} end={20} duration={2} delay={0} /> +</h1>
                    <span className='title'>Awards Recieved</span>
                </div>} 
        </ScrollTrigger>

        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
            {counterOn && <div className='section'>
                    <h1><CountUp start={0} end={50} duration={2} delay={0} /> +</h1>
                    <span className='title'>Expert <br /> Staffs</span>
                </div>} 
        </ScrollTrigger>

        <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
            {counterOn && <div className='section'>
                    <h1><CountUp start={0} end={100} duration={2} delay={0} /> +</h1>
                    <span className='title'>Happy Customers</span>
                </div>} 
        </ScrollTrigger>
    </section>
  )
}

export default Record;