import React from 'react';
import "./AboutUs.scss";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function AboutUs() {
  return (
    <section className='aboutUs_Container'>
        <div className="left_content">
            <h1><span>About</span> Us</h1>
            <p>
                {/* The World Health Organization states that the number of people aged 60 and over is expected to double by 2050, and that the number of people aged 80 and over is expected to triple. WHO also states that the care of older people is a global challenge, and that there is a need for increased investment in health and social care services to meet the needs of this growing population. It recommends that the need is to develop policies and strategies to ensure that older people have access to quality health and social care services, and that these services are tailored to meet the needs of older people. <br /><br />
                Keeping this in mind, We initiate to start The Center Care, which encompasses a variety of services and programs designed to meet the needs of adults. It focuses on providing basic care and assistance with daily activities to providing more specialized care for those with chronic illnesses or disabilities. The Center Care also includes providing emotional and social support, helping with financial planning, and providing access to resources and services.  */}

                Centre Care Inc is a reputable group home that offers exceptional living services to individuals with disabilities. We are located in South Minneapolis, MN, at 3601 Portland Ave, and we currently have two open beds available for prospective clients.<br />
                Our facility boasts of 24-hour nursing staff who are always available to provide all necessary nursing care services. We cater to individuals who have EW, AC, DD, CADI & TBI Waivers, and we are well-equipped to meet the needs of our clients when it comes to housing placement. We take pride in offering excellent services to our clients, and our staff members are trained to provide top-notch services that are tailored to each client's specific needs.<br />
                At Centre Care Inc, we provide a safe and secure environment for our clients, and our staff are trained in de-escalation techniques to handle mental health patients in times of crisis. We also offer 24-hour supervision, home care aide tasks, meal preparation, and transportation services to our clients.<br />
            </p>
        </div>


        <div className='accordions'>
            <Accordion >
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography className='title'>What types of adult care services do you offer? </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography className='answer'>
                We offer a variety of adult care services including Companionship, personal care services, nursing care and medical assistance. 
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                >
                <Typography className='title'>How do I know if my loved one needs adult care services? </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography className='answer'>
                    If your loved one is having difficulty with daily activities such as bathing, dressing, or managing medications, they may benefit from adult care services.
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
                >
                <Typography className='title'>How do I pay for Center care services? </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography className='answer'>
                    There are several options for paying us, including private pay, Medicaid, and long-term care insurance. We can help you explore and understand the different payment options available to you.
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4a-content"
                id="panel4a-header"
                >
                <Typography className='title'>What qualifications do your caregivers have? </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography className='answer'>
                    Our caregivers are carefully screened and trained to provide high-quality care.
                </Typography>
                </AccordionDetails>
            </Accordion>

            <Accordion>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5a-content"
                id="panel5a-header"
                >
                <Typography className='title'>Can you provide care for individuals with specific medical conditions or disabilities? </Typography>
                </AccordionSummary>
                <AccordionDetails>
                <Typography className='answer'>
                    Yes, we have experience and expertise in caring for individuals with a wide range of medical conditions and disabilities. We will work closely with your loved one's doctor and other healthcare providers to ensure that we are providing the appropriate care and support.
                </Typography>
                </AccordionDetails>
            </Accordion>
        </div>

    </section>
  )
}

export default AboutUs