import "./Hero.scss";

import React from 'react';
import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar"



function Hero() {
  return (
    <section className="hero_section_about">
        <Navbar />
        
        <div className="text_container">
            <h4>Reliable and affordable.</h4>
            <h1><span>Trustworthy companion of</span> your loved ones.</h1>
            <p>Specialized care for seniors.</p>
            <Link to='/contact' className="hero-btn">Get help</Link>
        </div>
    </section>
  )
}

export default Hero;


   // -webkit-text-stroke: 2px #fff;
