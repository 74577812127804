import React from 'react';
import { Link } from 'react-router-dom';
import "./WhyCC.scss";

function WhyCC() {
  return (
    <section className='cc_container'>
        <div className="left_content">
            <h1><span>Why</span> Center Care?</h1>
            <p>
              Adult care homes are needed to provide a safe and secure living environment for elderly adults who are no longer able to live independently. Center Care provides a range of services, including assistance with activities of daily living, medication management, and social activities.<br />
              We also provide a sense of community and companionship for residents, which can be especially beneficial for the adults who are isolated or have limited family support.
              We have a team of well-skilled and experienced caregivers who are committed to provide the best care to their clients. 


            </p>
            <button><Link to="/" className='btn'>Learn More</Link></button>
        </div>

        <div className="right_imgCard">
            
        </div>
    </section>
  )
}

export default WhyCC;