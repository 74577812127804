import React from 'react';
import "./RightImgService.scss";


function RightImgService() {
  return (
    <section className='service_container'> 
        <div className="left_content">
            <h1>PERSONAL CARE SERVICES</h1>
            <p>
              Personal care services provide a range of services that help adults with daily activities such as bathing, dressing, grooming, toileting, and eating. These services are provided by trained caregivers and can be tailored to meet the individual needs of the clients. Personal care services also include assistance with medication management, transportation, and other activities of daily living.
            </p>
        </div>

        <div className="right_imgCard" />
    </section>
  )
}

export default RightImgService;