import "./Navbar.scss";

import React, {useState} from 'react';
import { FaBars } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import {Link} from "react-router-dom";
import logo from "../../../assets/Home_Images/logo.png";



function Navbar() {

  const [Mobile, setMobile] = useState(false);

  return (
    <section className="navbar_section">
        <div className={Mobile ? "nav_items_mobile" : "nav_items"}>
          <Link to= "/" className="link">Home</Link>
          <Link to= "/about" className="link">About</Link>
          <Link to= "/" className="logo logo_che">
            <img src={logo} alt="logo" />
          </Link>
          <Link to= "/service" className="link">Services</Link>
          <Link to= "/contact" className="link">Contact</Link>
        </div>
        
        <button className="Mobile-menu-btn" onClick={() => setMobile(!Mobile)}>
            {Mobile ? <RxCross1 /> : <FaBars />}
          </button>
    </section>
  )
}

export default Navbar;