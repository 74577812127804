import React from 'react';
import "./Offer.scss";

function Offer() {
  return (
    <section className='offer_container'>
        <h1><span>Services</span> We Offer</h1>
        <p>
          We believe in delivering the services that are based on excellence, professionalism, well-maintenance and ethics. Care center is blessed to have a team of dedicated experts with years of experience in the sphere of care industry. The care is provided in a variety of settings, including in-home care, assisted living facilities, nursing homes, and other residential care facilities. At the Center care, it is an important part of ensuring that adults are able to live healthy, independent lives.
        </p>
    </section>
  )
}

export default Offer;