import React from 'react';
import "./CoreValues.scss";
import icon1 from '../../../assets/Home_Images/respect-icon.png'
import icon2 from '../../../assets/Home_Images/quality-icon.png'
import icon3 from '../../../assets/Home_Images/collaboration-icon.png'

function CoreValues() {
  return (
    <section className='core_container'>
        <div className="top_content">
            <h1><span>Our Core</span> Values</h1>
            <p>We sincerely pay regard to the virtues and values that we are committed to while delivering our services. <br />
            Some of the crucial values that we believe in are;
              {/* <ol type='1'>
                <li><b>Respect:</b> We respect the dignity, individuality and autonomy of our clients.</li>
                <li><b>Quality:</b> At Center care, we are committed to providing high-quality care and services that meet industry standards and regulations.</li>
                <li><b>Safety:</b> We have prioritized the safety and well-being of clients and staff and have policies and procedures prepared to reduce the risk of accidents or injuries.</li>
                <li><b>Collaboration:</b> Center care work in collaboration with clients, families, staff, and other stakeholders to ensure that the best possible care and services are provided.</li>
              </ol> */}
 
            </p>
        </div>
        <div className="bottom_card">
            <div className="card">
              <img src={ icon1 } alt="respect-icon" />
              <h3>Respect</h3>
            </div>
            <div className="card">
              <img src={ icon2 } alt="quality-icon" />
              <h3>Quality</h3>
            </div>
            <div className="card">
                <img src={ icon3 } alt="collaboration-icon" />
                <h3>Collaboration</h3>
            </div>
        </div>
    </section>
  )
}

export default CoreValues;