import "./Hero.scss";

import React from 'react';
import { Link } from "react-router-dom";
import Navbar from "../Navbar/Navbar"



function Hero() {
  return (
    <section className="hero_section_service">
        <Navbar />
        
        <div className="text_container">
            <h4>Providing compassionate care for adults in need.</h4>
            <h1><span>Tailoring services that benefit</span> you perfectly.</h1>
            <p>We are here to support you and your loved one every step of the way.</p>
            <Link to='/contact' className="hero-btn">Get help</Link>
        </div>
    </section>
  )
}

export default Hero;


   // -webkit-text-stroke: 2px #fff;
