import React from 'react';
import AboutUs from '../components/About_Components/AboutUS/AboutUs';
import Contact from '../components/About_Components/ContactUs/Contact';
import Record from '../components/About_Components/CountRecord/Record';
import Hero from '../components/About_Components/Hero/Hero';
import Footer from '../components/About_Components/Footer/Footer';
import { Tabtitle } from '../utils/GeneralFunction';


function About() {
  Tabtitle('Center Care - about')

  return (
    <>
      <Hero />
      <AboutUs />
      <Record />
      <Contact />
      <Footer />
    </>
  )
}

export default About;