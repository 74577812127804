import React from 'react';
import "./FourthService.scss";

function FourthService() {
  return (
    <section className='service_container'> 
        <div className="left_content">
            <h1>MEDICAL ASSISTANCE</h1>
            <p>
              Medical assistance is a vital part of providing quality care for adults. It includes a variety of services, such as providing medical advice, helping with daily activities, and providing transportation to medical appointments. Our caregivers are experts in providing companionship, listening to concerns, and providing emotional support during difficult times.
            </p>
        </div>

        <div className="right_imgCard4" />
    </section>
  )
}

export default FourthService;