import React from 'react';
import "./OurServices.scss";


function OurServices() {
  return (
    <section className='service_container'>
        <div className="left_imgCard1" />

        <div className="right_content">
            <h1>COMPANIONSHIP</h1>
            <p>
              Through Companionship services, we provide social and emotional support to elderly adults.These services can include activities such as conversation, playing games, going for walks, and providing emotional support. The goal of companionship services is to help our elderly adults maintain a sense of purpose and connection to the world around them.
            </p>
        </div>
    </section>
  )
}

export default OurServices;