import React from 'react';
import "./ThirdService.scss";

function ThirdService() {
  return (
    <section className='service_container'>
        <div className="left_imgCard" />

        <div className="right_content">
            <h1>NURSING CARE</h1>
            <p>
                Nursing care services involve providing comprehensive care to adults with physical, mental, and emotional health needs. This includes providing medical care, monitoring vital signs, administering medications, providing emotional support, and helping with activities of daily living. Nursing care services also involve educating patients and families about health and wellness, providing referrals to other healthcare professionals, and advocating for the patient's rights and needs.
            </p>
        </div>
    </section>
  )
}

export default ThirdService;